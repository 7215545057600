import { useEffect, useRef } from 'react';

/**
 * Runs a callback function with a delay using setInterval.
 *
 * @param {function} callback - Function to by called when running setInterval function.
 * @param {number} delay - Time in ms between running the callback in the setInterval.
 * @param {array[any]} dependencies - Time in ms between running the callback in the setInterval.
 * @param {boolean} initialCall - Whether to run the callback imidiatly after a dependency has changed.
 *
 * @return void;
 */
const usePollingHook = (callback, delay = 30000, dependencies = [callback], initialCall = true) => {
    const intervalRef = useRef();

    // useEffect to update poll when params change.
    useEffect(() => {
        const clearPoll = () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        };

        const setupInterval = () => {
            // The stuff is wrapped in callback, so that if the callback then turns to null, it will clear the interval.
            // And won't run if the callback isnt provided.
            if (callback) {
                // Call function up front.
                if (!intervalRef.current && initialCall) callback();

                // Ensure it's not clear
                clearPoll();

                // Set interval function to run callback function provided.
                intervalRef.current = setInterval(() => callback(), delay);
            } else {
                clearPoll();
            }
        };

        setupInterval();

        const visibilityChange = () => {
            if (document.visibilityState === 'visible') {
                setupInterval();
            } else {
                clearPoll();
            }
        };

        document.addEventListener('visibilitychange', visibilityChange);

        // Clear interval on dismount.
        return () => {
            clearPoll();
            document.removeEventListener('visibilitychange', visibilityChange);
        };
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

export default usePollingHook;
