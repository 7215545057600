import { SITE_ORIGIN } from '../config/config';
import { replaceTrailingSlash } from './text/textFormatting';

/**
 * Sends message to parent iframe or window opener
 * @param {string} messageName
 * @param {string} [value] - a value
 * @param {boolean} [close] - close frame or not
 *
 * PLEASE READ SECURITY FOR postMessage - https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 */
const sendMessageToParentFrame = (messageName, value, close = false) => {
    /** parent page from iframe */
    if (window.parent) {
        window.parent.postMessage({ message: messageName, value }, replaceTrailingSlash(SITE_ORIGIN));
        if (close) window.close();
    }

    if (window.opener) {
        window.opener.postMessage({ message: messageName, value }, replaceTrailingSlash(SITE_ORIGIN));
        if (close) window.close();
    }
};

export default sendMessageToParentFrame;
