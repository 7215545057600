/* eslint-disable react/jsx-props-no-spreading */
/**
 * overwrote this rule as we need it in here to be able to pass props in.
 */
import React from 'react';
import { Location } from '@reach/router';
import PropTypes from 'prop-types';

// The object shape of the location prop passed in.
export const locationShape = PropTypes.shape({
    hash: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
    protocol: PropTypes.string,
    state: PropTypes.shape({
        key: PropTypes.string,
    }),
});

const withLocation = (ComponentToWrap) => (props) =>
    <Location>{({ location, navigate }) => <ComponentToWrap {...props} location={location} navigate={navigate} />}</Location>;

export default withLocation;
