import queryString from 'query-string';

/**
 *
 * @param {*} regex
 * @returns {{[key: string]: string}} - key value
 */
export const getQueryStringObj = (regex = /[^a-zA-Z0-9-\s.,]/g) => {
    const inBrowser = typeof document !== 'undefined';

    if (!inBrowser) {
        return {};
    }

    const searchParams = document.location.search ? queryString.parse(document.location.search) : {};

    const saveSearchParams = {};

    Object.keys(searchParams).forEach((k) => {
        if (searchParams[k]) saveSearchParams[k] = searchParams[k].replace(regex, '');
    });

    // Strip the string of everything exept numbers, letters and strings.
    return saveSearchParams || {};
};

const getQueryString = (key) => {
    const inBrowser = typeof document !== 'undefined';

    if (!inBrowser) {
        return undefined;
    }

    const searchParams = getQueryStringObj();

    // Strip the string of everything exept numbers, letters and strings.
    return searchParams[key] ? searchParams[key] : undefined;
};

export default getQueryString;
