import useTreatments from './useTreatment';

/**
 * Pass in a system id and we get a static query to loop through all the treatments and system id's of treatments
 * in craft to create an index that we can use to bring back the featured image stored in craft, when we need
 * to use it in dynamically generated places, such as in the account and in the basket
 * @param {number | string} systemId - system id that matches the treatment's id in the api
 */
const useTreatmentImage = () => {
    const { treatmentIndex } = useTreatments();

    const getTreatmentFeaturedImage = (systemId) => (systemId && treatmentIndex[systemId] ? treatmentIndex[systemId].imgSrc : null);
    const getTreatmentMediumImage = (systemId) => (systemId && treatmentIndex[systemId] ? treatmentIndex[systemId].imgMediumSrc : null);

    return {
        getTreatmentImage: getTreatmentFeaturedImage,
        getTreatmentFeaturedImage,
        getTreatmentMediumImage,
    };
};

export default useTreatmentImage;
