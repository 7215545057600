/* eslint-disable import/no-relative-packages */

import addScript from "../../../src/helpers/addScript";
import { localStorageGet, localStorageSet } from "../../../src/hooks/useLocalStorageSync";
import config, { SENTRY_SRC } from "../config/config";
import PluginOptions from "../types/PluginOptions";

const initSentry = (pluginOptions: PluginOptions) => {
    const scriptAttributes = [
        { key: 'key', value: 'sentryscript' },
        { key: 'integrity', value: 'sha384-hddl7X3flGhIMJgLUpwZDzLOWf8GDApKvKos/jS4z7Uz/sejXA0thkp9JCZgoDG4' },
        { key: 'crossOrigin', value: 'anonymous' },
        { key: 'defer', value: 'defer' },
    ];

    const onScriptLoad = () => {
        if (typeof window === "undefined" || !window.Sentry || !pluginOptions.dsn) {
            return;
        }

        const sentryConfig = {
            ...config(),
            ...pluginOptions,
        };

        window.Sentry.init(sentryConfig);

        if (window.localStorage) {
            // ssid = sentry session id (created and stored in local storage in our custom sentry plugin to distinguish the user from another user)
            let sentrySessionId = localStorageGet("ssid") || null;

            if (!sentrySessionId) {
                sentrySessionId = Math.random().toString(36).substr(2, 9);
                localStorageSet("ssid", sentrySessionId);
            }

            window.Sentry.configureScope((scope: any) => {
                scope.setTag("transaction_id", sentrySessionId);
            });

            window.Sentry.setUser({ id: sentrySessionId, username: sentrySessionId });
        }
    };

    setTimeout(() => addScript(SENTRY_SRC, onScriptLoad, scriptAttributes), 500);
};

export default initSentry;
