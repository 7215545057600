import { isBrowser } from '../config/config';

/**
 * sendSentryMessage
 * @param {(scope:any) => void} [extraScope] = (scope) => { if (treatmentData) scope.setExtra('Treatment Data', treatmentData); }
 */
const sendSentryMessage = (message, extraScope) => {
    if (typeof window !== 'undefined' && window.Sentry) {
        // eslint-disable-next-line no-undef
        Sentry.withScope((scope) => {
            if (typeof extraScope === 'function') {
                extraScope(scope);
            }

            // eslint-disable-next-line no-undef
            Sentry.captureMessage(message);
        });
    }
};

if (isBrowser()) {
    window.enableSentryTestMessage = () => {
        window.testSentryMessage = (message, extraScope) => sendSentryMessage(message, extraScope);
    };
}

export default sendSentryMessage;
