import { graphql, useStaticQuery } from 'gatsby';

/**
 * This is when we want to use total treatment and condition counts around the system.
 * @returns
 */
const useTotalTreatmentAndConditionsCount = () => {
    const data = useStaticQuery(graphql`
        {
            allCondition(filter: { computed: { isSchemaEntry: { eq: false } } }) {
                totalCount
            }
            nonParentTreatments: allTreatment(filter: { computed: { parentEntry: { eq: false }, isSchemaEntry: { eq: false } } }) {
                totalCount
            }
        }
    `);

    const treatmentCount = data && data.nonParentTreatments ? data.nonParentTreatments.totalCount : 400;
    const conditionCount = data && data.allCondition ? data.allCondition.totalCount : 60;

    return { treatmentCount, conditionCount };
};

export default useTotalTreatmentAndConditionsCount;
