import dayjs from 'dayjs';
import get from 'lodash.get';

/**
 * createAnswerEntity, returns answer object.
 * @param {object} newAnswer - pass in a new answer object
 * @param {object} storedAnswer - (optional) pass in existing answer and it will be merged with the new answer
 * @returns
 */
const createAnswerEntity = (answer, question = {}) => {
    // If the question is conditional (a previous question answer shows or hides it), then force it to always be required.
    const conditionalID = get(question, 'conditional.id', false);
    const required = conditionalID ? 1 : !!question.required;

    return {
        type: question.type,
        detail: answer.detail,
        moreDetailDirty: typeof answer.moreDetailDirty !== 'undefined' ? answer.moreDetailDirty : true,
        isDirty: typeof answer.isDirty !== 'undefined' ? answer.isDirty : true,

        answer: answer.answer,

        // If the specific answer of the question is valid.
        answerValid: typeof answer.answerValid !== 'undefined' ? answer.answerValid : !required,

        // If the answer and more detail (if applicable) are valid.
        questionValid: typeof answer.questionValid !== 'undefined' ? answer.questionValid : !required,

        internalQuestionError: answer.internalQuestionError,

        answered_on: answer.answered_on ? dayjs(answer.answered_on, 'YYYY-MM-DD HH:mm:ss').utc().valueOf() : dayjs.utc().valueOf(),
    };
};

export default createAnswerEntity;
