/* eslint-disable camelcase */
import get from 'lodash.get';

import { affiliateInScope } from '../../../affiliate/helpers/affiliateInScope';
import { getAffiliateJwtDecoded } from '../../../affiliate/helpers/getAffiliateJwt';
import ProductTypes from '../../../../types/api/products/ProductTypes';
import { BasketItem } from '../../../../types/api/basket/BasketItem';
import ConsultationTypes from '../../../../types/api/Consultation/ConsultationTypes';

type getTreatmentImageType = (reference: number) => string;
type getTreatmentDescriptionType = (reference: number) => string;

interface ReturnType {
    ecommerce: {
        add: {
            products: [
                {
                    name: string | null;
                    id: number;
                    category: string;
                    quantity: number;
                    price: string;
                    variant: string;
                    dimension2: 'Logged in';
                    dimension3: ProductTypes;
                    dimension4: 'Default Variant' | 'Not Default Variant';
                    dimension5: ConsultationTypes;
                    dimension15: string | null;
                }
            ];
        };
    };
    freshDataLayer: {
        treatment: string;
        desc: string;
        image: string;
        name: string;
        id: number;
        quantity: number;
        wasPrice: string;
        nowPrice: string;
        sku: number;
        stock: number | null;
        url: string;
    };
}

/**
 * Formats add to basket data for analytics.
 * @param {BasketItem} baketItem - The item added to the basket.
 * @param {boolean} isLoggedIn - If there is a patient logged in.
 * @param {getTreatmentImageType} getTreatmentImage - Gets the treatments image.
 * @param {getTreatmentDescriptionType} getTreatmentDescription - Gets the treatments description.
 * @returns {returnType}
 */
const formatAddToBasketAnalytics = (
    baketItem: BasketItem,
    getTreatmentImage: getTreatmentImageType,
    getTreatmentDescription: getTreatmentDescriptionType
): ReturnType => {
    const { total, is_default_variant, reference, condition_name, quantity, treatment, unit_cost, type, order_type } = baketItem;

    const formattedPrice = (total / 100).toFixed(2);
    const isDefaultVariant = is_default_variant ? 'Default Variant' : 'Not Default Variant';

    const formattedReference = typeof reference === 'string' ? parseInt(reference, 10) : reference;

    return {
        ecommerce: {
            add: {
                products: [
                    {
                        name: get(baketItem, 'product_name') || null, // Treatment name
                        id: formattedReference, // Variant id/sku
                        category: condition_name, // Treatment condition
                        quantity, // Variant quantity
                        price: ((unit_cost || 0) / 100).toFixed(2), // Variant unit cost
                        variant: treatment, // Variant name
                        dimension2: 'Logged in', // Logged in status
                        dimension3: type.toUpperCase() as ProductTypes, // PRODUCT, PMED, ODONLY
                        dimension4: isDefaultVariant, // Was the default variant selected
                        dimension5: order_type, // Reorder or first time order

                        dimension15: affiliateInScope(formattedReference) ? get(getAffiliateJwtDecoded({}), 'affiliate_code', null) : null // If there is an affiliate code applied
                    }
                ]
            }
        },
        freshDataLayer: {
            treatment: condition_name, // Treatment condition
            desc: getTreatmentDescription(formattedReference), // Variant description
            image: getTreatmentImage(formattedReference), // Variant image
            name: treatment, // Variant name
            id: formattedReference, // Variant id/sku
            quantity, // Variant quantity
            wasPrice: formattedPrice, // Variant price
            nowPrice: formattedPrice, // Variant price
            sku: formattedReference, // Variant id/sku
            stock: get(baketItem, 'stock') || null, // Variant stock
            url: window.location.href,
        },
    };
};

export default formatAddToBasketAnalytics;
