/* eslint-disable */
import React, { useMemo, useRef } from 'react';
import { Transition } from '@headlessui/react';

import { isBrowser } from '../../../../config/config';
import useSiteBanner from '../../hooks/useSiteBanner';

import Button from '../../../../components/_new_components/common/Buttons/Button/Button';
import BaseIcon from '../../../../components/_new_components/common/Icons/BaseIcon/BaseIcon';
import IconCross from '../../../../components/_ui/_blocks/Icons/Navigation/IconCross';

import * as styles from './SiteBanner.module.css';

const SiteBanner = () => {
    const { siteBanners, markBannerAsSeen } = useSiteBanner();
    const firstSiteBanner = useMemo(() => (siteBanners.length ? siteBanners[0] : null), [siteBanners]);
    const hasAnimatedRef = useRef<boolean>(false);

    if (!isBrowser()) {
        return null;
    }

    const handleClose = () => {
        hasAnimatedRef.current = true;
        markBannerAsSeen(firstSiteBanner?.uuid || '');
    };

    return (
        <Transition
            key={firstSiteBanner?.uuid || 'banner'}
            show={!!firstSiteBanner}
            className="@container"
            enter="transition-all duration-150"
            enterFrom="opacity-100"
            enterTo="opacity-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-100"
            unmount
        >
            <div className={`${styles.siteBanner} ${!hasAnimatedRef.current ? styles.animateHeight : styles.staticHeight}`}>
                <div className={styles.siteBannerContent}>
                    <div className="grow">
                        <div
                            className={styles.siteBannerWYSIWYG}
                            dangerouslySetInnerHTML={{
                                __html: firstSiteBanner?.body || '',
                            }}
                        />
                    </div>
                    <Button type="button" variant="none" size="none" title="Close" onClick={handleClose}>
                        <BaseIcon color="white" size="small">
                            <IconCross />
                        </BaseIcon>
                    </Button>
                </div>
            </div>
        </Transition>
    );
};

export default SiteBanner;
