import React from 'react';

import AccountLayout from './AccountLayout/AccountLayout';
import CheckoutLayout from './CheckoutLayout/CheckoutLayout';
import PPCLayout from './PPCLayout/PPCLayout';
import DefaultLayout from './DefaultLayout/DefaultLayout';
import AffiliateLayout from './AffiliateLayout/AffiliateLayout';
import MinimalLayout from './MinimalLayout/MinimalLayout';

const layoutConfig = [
    {
        layoutName: 'NoLayout',
        // eslint-disable-next-line react/jsx-no-useless-fragment
        layoutComponent: ({ children }) => <React.Fragment>{children}</React.Fragment>,
        pathsToMatch: ['/auth', '/payment-success', '/payment-error'],
    },
    {
        layoutName: 'Account',
        layoutComponent: AccountLayout,
        pathsToMatch: ['/account'],
    },
    {
        layoutName: 'Checkout',
        layoutComponent: CheckoutLayout,
        pathsToMatch: ['/checkout', '/order-confirmed', '/prescriptions/apply'],
    },
    {
        layoutName: 'Minimal',
        layoutComponent: MinimalLayout,
        pathsToMatch: ['/maintenance'],
    },
    {
        layoutName: 'PPC',
        layoutComponent: PPCLayout,
        sectionHandleToMatch: ['payPerClick'],
    },
    {
        layoutName: 'productAndPmedTreatmentPPCPages',
        layoutComponent: DefaultLayout,
        pathsToMatch: ['/gppc'],
    },
    {
        layoutName: 'AffiliateLayout',
        layoutComponent: AffiliateLayout,
        pageContextAffiliate: true,
    },
];

/**
 * This is our master layout file
 * it uses gatsby's wrapPageLayout file in the gatsby-browser and gatsby-ssr
 * By default we render the normal layout component, or we loop through our config.
 */
const layoutWrap = ({ element, props }) => {
    /** Set default layout */
    let LayoutToRender = DefaultLayout;
    const path = props.location.pathname;

    /** Find layouts by path. */
    let foundLayout = layoutConfig.find((layoutConf) =>
        layoutConf.pathsToMatch ? layoutConf.pathsToMatch.find((p) => path.indexOf(p) > -1) : false
    );

    /** Find layouts by sectionHandle.  */
    if (!foundLayout) {
        foundLayout = layoutConfig.find((layoutConf) =>
            layoutConf.sectionHandleToMatch
                ? layoutConf.sectionHandleToMatch.find((sectionHandle) => props.pageContext.sectionHandle === sectionHandle)
                : false
        );
    }

    /** Find layouts by affiliate page context.  */
    if (!foundLayout && props.pageContext.affiliate) {
        foundLayout = layoutConfig.find((layoutConf) => layoutConf.pageContextAffiliate === true);
    }

    if (foundLayout) {
        LayoutToRender = foundLayout.layoutComponent;
    }

    return <LayoutToRender {...props}>{element}</LayoutToRender>;
};

export default layoutWrap;
