import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAuthContext } from '../authContext';
import { calculateAge } from '../../../helpers/dateFunctions/dateFunctions';
import { formatAssessment } from './helpers/assessmentFormatter';
import useDataApi from '../../../hooks/useDataApi';
import assessmentService from '../../services/assessment.service';
import useQuestionManager from '../../../components/Questions/QuestionTypes/hooks/useQuestionManager';

const AssessmentContext = createContext();

/**
 * @param {*} props
 */
export const AssessmentProvider = ({ tName, ...props }) => {
    const [treatmentName, setTreatmentName] = useState(tName);
    const [contactDetails, setContactDetails] = useState(null);

    const { patient, loadingPatient } = useAuthContext();

    /**
     * Add contact details to assessment if there is patient data.
     */
    useEffect(() => {
        if (contactDetails || loadingPatient) return;

        if (!patient) {
            setContactDetails({});
            return;
        }

        const { personal, email, identity, contact_number: tel } = patient;

        const fullName = `${identity.firstname} ${identity.lastname}`;
        const age = calculateAge(personal.dateofbirth);

        setContactDetails({
            fullName,
            age,
            email,
            tel,
        });
    }, [patient, contactDetails, loadingPatient]);

    const [{ isLoading: isSubmitting, error: submitErrors }, postAssessment] = useDataApi(
        (formData) => assessmentService.postAssessment(formData),
        []
    );

    /**
     * Used to manage questions and answers in the assessment panels.
     */
    const {
        answers: symptomAnswers,
        updateAnswer: updateSymptomAnswers,
        checkAnswersAreValidAndFilterAnswers: checkSymptomAnswersAreValidAndFilterAnswers,
        clearAnswers: clearSymptomAnswers,
    } = useQuestionManager();

    const {
        answers: medicalAnswers,
        updateAnswer: updateMedicalAnswer,
        checkAnswersAreValidAndFilterAnswers: checkMedicalAnswersAreValidAndFilterAnswers,
        clearAnswers: clearMedicalAnswers,
    } = useQuestionManager();

    /**
     * Sends all the question data back to the API.
     */
    const submitAssessment = useCallback(async () => {
        const answers = { ...symptomAnswers, ...medicalAnswers };
        const formattedAssessmentData = formatAssessment({ treatmentName, contactDetails, answers });

        return postAssessment(formattedAssessmentData);
    }, [contactDetails, symptomAnswers, medicalAnswers, postAssessment, treatmentName]);

    /**
     * Clears symptom, contact and medical answers.
     */
    // TODO: May need to clear contact details here if logged in?
    const clearAssessmentAnswers = () => {
        clearSymptomAnswers();
        clearMedicalAnswers();
        if (!patient) setContactDetails(null);
    };

    /**
     * Clears treatment name and form answers.
     */
    const closeAssessment = () => {
        setTreatmentName(null);
        clearAssessmentAnswers();
    };

    // TODO: We need to add a use memo to this to prevent re-renders (contextValueRefactor, Ticket: 862jfuy9w).
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
        treatmentName,
        setTreatmentName,

        contactDetails,
        setContactDetails,

        symptomAnswers,
        updateSymptomAnswers,
        checkSymptomAnswersAreValidAndFilterAnswers,

        medicalAnswers,
        updateMedicalAnswer,
        checkMedicalAnswersAreValidAndFilterAnswers,

        isSubmitting,
        submitErrors,
        submitAssessment,
        closeAssessment,
        clearAssessmentAnswers,
    };

    return <AssessmentContext.Provider {...props} value={value} />;
};

AssessmentProvider.defaultProps = {
    tName: null,
};

AssessmentProvider.propTypes = {
    tName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

/**
 * Hook to be used in components that will allow us access to the values passed into the search
 * provider above.
 * @param {*} showContextError - sometimes we want to use this but fail silently, such as we need the context on a sign up form, as its required whilst being in the consultation signup
 */
export const useAssessmentContext = (showContextError = true) => {
    const context = useContext(AssessmentContext);

    if (context === undefined && showContextError) {
        throw new Error('useAssessmentContext must be used within a AssessmentProvider');
    }
    return context;
};
