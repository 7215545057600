import axios from 'axios';

// import { MSW_URL } from '../../config/config';

export default {
    /**
     * To get a product, its delivery date potential, blockers and all variants to be able to be selected by
     * the front end system.
     * @param {string} uuid - Unique ID of the product to get.
     */
    getProduct: async (uuid: number) => axios.get(`/products/${uuid}`),

    getProductRefills: async (conditionId: number) => axios.post('/products/refills', { conditionId }),

    getProductRecommendations: async (conditionId: number, treatment?: number) => {
        const body = {
            conditionId,
        } as Record<string, any>;

        if (treatment) {
            body.treatment = treatment;
        }

        return axios.post('/products/recommendation', body);
    },

    getAll: async (treatmentIds?: number[], withPublicData = true, filterPriceBreaksByStock = true) => {
        const queries = [`filterPriceBreaksByStock=${filterPriceBreaksByStock}`];

        if (treatmentIds && treatmentIds.length) {
            queries.push(`product_ids=${treatmentIds.join(',')}`);
        }

        if (withPublicData) {
            queries.push('withPublic');
        }

        const query = queries.length ? encodeURI(`?${queries.join('&')}`) : '';

        return axios.get(`/products${query}`);
    },
};
