/**
 * Formats an order item / basket item / basket item quantity label.
 * @param {object} item - An order item.
 * @param {string} labelDefault - Default label if there is no label.
 * @returns {string}
 */
const formatQuantityLabel = (item, labelDefault = 'x') => {
    if (!item) {
        return '';
    }

    // customer_label has priority if available.
    if (item.customer_label) return item.customer_label;

    if (!item.quantity) return '';

    // Sets the label and returns with quantity.
    return `${item.quantity} ${item.pack_label || item.label || labelDefault}`;
};

export default formatQuantityLabel;
