import { navigate } from 'gatsby';

import auth from '../auth';
import { AuthToken } from '../../../types/api/auth/AuthToken';
import dispatchGoogleAnalyticsEvent, { gaEvents, gaFormatters } from '../../analytics/google/dispatchGoogleAnalyticsEvent';

export const MESSAGE_REGISTER = 'AUTH_NAVIGATE_TO_REGISTER';
export const MESSAGE_CONTACT = 'AUTH_NAVIGATE_TO_CONTACT_US';
export const MESSAGE_SUCCESSFUL_SIGN_IN = 'AUTH_SUCCESS';
export const MESSAGE_LOGIN_PRESSED = 'LOGIN_PRESSED';

/** Message handler for register message from iframe  */
const handleRegister = () => navigate('/register');

/** Message handler for contact message from iframe */
const handleContact = () => navigate('/contact-us');

/** Message handler for a successful sign-in message from iframe */
const handleSuccessfulSignIn = (value: AuthToken, onSubmitSuccess: () => void) => {
    auth.setAuthTokens(value);

    dispatchGoogleAnalyticsEvent(gaEvents.UserLogin, gaFormatters.userLogin(value));

    if (onSubmitSuccess) {
        onSubmitSuccess();
    }
};

/** sign in iframe message parser. */
const signInIframeMessageParser = (message: string, value: unknown, onSubmitSuccess: () => void): void => {
    if (message === MESSAGE_REGISTER) {
        handleRegister();
    }
    if (message === MESSAGE_CONTACT) {
        handleContact();
    }
    if (message === MESSAGE_SUCCESSFUL_SIGN_IN) {
        const authToken = value as AuthToken;
        handleSuccessfulSignIn(authToken, onSubmitSuccess);
    }
};

export default signInIframeMessageParser;
