import React from 'react';

const IconChevronRight = () => (
    <path
        fillRule="evenodd"
        d="M11.5858 0.585786C12.3668 -0.195262 13.6332 -0.195262 14.4142 0.585786L36.4142 22.5858C37.1953 23.3668 37.1953 24.6332 36.4142 25.4142L14.4142 47.4142C13.6332 48.1953 12.3668 48.1953 11.5858 47.4142C10.8047 46.6332 10.8047 45.3668 11.5858 44.5858L32.1716 24L11.5858 3.41421C10.8047 2.63317 10.8047 1.36683 11.5858 0.585786Z"
    />
);

export default IconChevronRight;
