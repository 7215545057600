import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ConfirmationProvider } from '../data/context/confirmationContext';
import { AuthProvider } from '../data/context/authContext';
import { BasketProvider } from '../data/context/basketContext/basketContext';
import { NavigationProvider } from '../data/context/navigationContext';
import { SearchProvider } from '../data/context/searchContext';
import { AssessmentProvider } from '../data/context/assessmentContext/assessmentContext';
import { AccountNavigationProvider } from '../data/context/accountContext/accountNavigationContext';
import { ConsultationReorderProvider } from '../data/context/consultationContext/consultationReorderContext';
import { ConsultationLauncherProvider } from '../data/context/consultationContext/consultationLauncherContext';

/**
 * Combine providers to remove nested soup.
 * To extend this we could pass in object or array and allow props to be passed in if required.
 * @param components, children
 * @returns
 */
// const CombineProviders = ({ components, children }) => components.reverse().reduce((acc, curr) => {
//     const Provider = curr;
//     return <Provider>{acc}</Provider>;
// }, children);

const queryClient = new QueryClient();

/**
 * This is our master provider file
 * it uses gatsby's wrapRootElement file in the gatsby-browser and gatsby-ssr
 */
const providerWrap = ({ element }) => (
    <QueryClientProvider client={queryClient}>
        <ConfirmationProvider>
            <AuthProvider>
                <SearchProvider>
                    <AssessmentProvider>
                        <NavigationProvider>
                            <AccountNavigationProvider>
                                <BasketProvider>
                                    <ConsultationLauncherProvider>
                                        <ConsultationReorderProvider>{element}</ConsultationReorderProvider>
                                    </ConsultationLauncherProvider>
                                </BasketProvider>
                            </AccountNavigationProvider>
                        </NavigationProvider>
                    </AssessmentProvider>
                </SearchProvider>
            </AuthProvider>
        </ConfirmationProvider>
    </QueryClientProvider>
);

export default providerWrap;
