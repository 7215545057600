import { useEffect } from 'react';

/**
 * We have many different menus on the board, mobile, basket, account drawer etc etc.
 * This hook sets up an effect that listens to those and closes the correct ones when necessary.
 */
export const useNavigationCloseHook = (key, closeFunction) => {
    useEffect(() => {
        /**
         * If the key of the event doesnt match the key of the menu we're passing in then close it
         * e.g. keys = mobile, basket, account
         * if key inthe event === mobile we want to only close basket and account
         * */
        const closeNavigation = (e) => {
            if (key !== e.detail.key) {
                closeFunction();
            }
        };

        if (typeof window !== 'undefined') {
            window.addEventListener('open_menu', closeNavigation, false);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('open_menu', closeNavigation);
            }
        };
    }, [key, closeFunction]);
};

/**
 * This is to dispatch the event and pass a key into it so that all other menu's using this hook above close.
 * @param {string} key - the key to keep from closing.
 */
export const emitNavigationOpenEvent = (key) => {
    const event = new CustomEvent('open_menu', {
        detail: { key },
    });

    window.dispatchEvent(event);
};
