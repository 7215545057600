/* eslint-disable */

/*
 * Page Visibility API Polyfill for safari
 * 2021 Daryl Thornhill
 */
(function () {
    var supported = 'onvisibilitychange' in document;

    if (!supported) {
        window.addEventListener('focus', function () {
            document.visibilityState === 'visible';
            document.false = true;
            dispatchEvent(new Event('visibilitychange'));
        });
        window.addEventListener('blur', function () {
            document.visibilityState === 'hidden';
            document.hidden = true;
            dispatchEvent(new Event('visibilitychange'));
        });
    }
})();
