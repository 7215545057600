import { useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';

import isLocalStorageAvailable from '../helpers/isLocalStorageAvailable';

/**
 * Remove local storage by key
 * @param {string} key - key of the local storage to remove
 */
export const localStorageRemove = (key) => {
    // If localStorage is not available.
    if (isLocalStorageAvailable === false) {
        return;
    }

    window.localStorage.removeItem(key);

    dispatchEvent(new Event('storage')); // As the storage event only fires on other tabs changing it we're adding this here
};

/**
 * Set local storage key with value
 * @param {*} key - key to set
 * @param {*} value - value/ object etc to stringify and set
 * @returns any
 */
export const localStorageSet = (key, value) => {
    // If localStorage is not available.
    if (isLocalStorageAvailable === false) {
        return;
    }

    if (typeof value === 'undefined' || value === 'undefined' || value === null || value === 'null') {
        localStorageRemove(key);
        return;
    }

    window.localStorage.setItem(key, JSON.stringify(value));

    dispatchEvent(new Event('storage')); // As the storage event only fires on other tabs changing it we're adding this here
};

/**
 * Get local storage value by key
 * @param {*} key - key to retrieve
 */
export const localStorageGet = (key) => {
    // If localStorage is not available.
    if (isLocalStorageAvailable === false) {
        return null;
    }

    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
};

/**
 * Resets all local storage and clears it all.
 */
export const localStorageClearAll = () => {
    // If localStorage is not available.
    if (isLocalStorageAvailable === false) {
        return;
    }

    window.localStorage.clear();
};

/**
 * Removes local storage items via keys in array paramater.
 * @param {array} items - Array of local storage keys.
 */
export const localStorageClearItems = (items = []) => {
    // If no items were provided to clear.
    if (!items.length) {
        return;
    }

    items.forEach((key) => {
        localStorageRemove(key);
    });
};

/**
 *
 * @param {*} key - Key to store against in local storage
 * @param {*} state - state that will get stringified and pushed into local stroage.
 */
const useLocalStorageSync = (key, state) => {
    useEffect(() => {
        // If localStorage is not available.
        if (isLocalStorageAvailable === false) {
            return;
        }

        if (key) localStorageSet(key, state);
    }, [key, state]);
};

/**
 * watches local storage key for any changes.
 * @param {string} key
 */
export const useLocalStorageWatcher = (key) => {
    // Check localStorage is available.
    const localStorageAvailable = isLocalStorageAvailable;

    // Store the stored item in state unless localStorage is not available.
    const [state, setState] = useState(localStorageAvailable ? localStorageGet(key) : null);

    useEffect(() => {
        // If localStorage is not available.
        if (localStorageAvailable === false) {
            return null;
        }

        const handler = () => {
            setState((s) => (!isEqual(s, localStorageGet(key)) ? localStorageGet(key) : s));
        };

        const visibilityChange = (e) => {
            if (document.visibilityState === 'visible') handler(e);
        };

        window.addEventListener('storage', handler);
        document.addEventListener(
            'visibilitychange',
            visibilityChange
        ); /** For when people load into the tab if they've been working with multiple tabs, resync state. */

        return () => {
            window.removeEventListener('storage', handler);
            document.removeEventListener('visibilitychange', visibilityChange);
        };
    }, [localStorageAvailable]);

    useEffect(() => {
        // If localStorage is not available.
        if (localStorageAvailable === false) {
            return;
        }

        const newVal = localStorageGet(key);

        if (newVal) setState(newVal);
    }, [key, localStorageAvailable]);

    return state;
};

export default useLocalStorageSync;
