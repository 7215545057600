import axios from 'axios';

export interface GetConsultationPanelPostData {
    conditionId: number | string;
    treatmentId?: number | string;
    quantity?: number;
    introRequired?: boolean;
}

export interface GetConsultationQuestionsPostDataTreatment {
    type: 'TREATMENT';
    treatmentId?: number | string;
}
export interface GetConsultationQuestionsPostDataCondition {
    type: 'CONDITION';
    conditionId?: number | string;
}

type GetConsultationQuestionsPostData = GetConsultationQuestionsPostDataTreatment | GetConsultationQuestionsPostDataCondition;

export default {
    /**
     * Passes data in and gets the consultation panels to display to the user.
     * We deconstruct this object as we may have extra details in here.
     */
    getConsultationPanels: async ({ conditionId, treatmentId, quantity, introRequired }: GetConsultationPanelPostData) =>
        axios.post('/consultations/panels', {
            conditionId,
            treatmentId,
            quantity,
            introRequired,
        }),

    /**
     * Returns the consultation required for the provided condition or treatmentid
     */
    getConsultationQuestions: async (postData: GetConsultationQuestionsPostData) => axios.post('/consultations/generate', postData),

    /**
     * When creating an asset the client provides a type to indicate the type of asset being uploaded.
     *
     * Available types include:
     * PATIENT_IMAGE - jpeg, jpg, gif, png - 4MB limit.
     *
     * @param {formDatobject} formData - { type, file }
     */
    uploadConsultationImage: async (formData: any) =>
        axios.post('/assets', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }),

    /**
     * Endpoint to validate a consultation before saving / submitting
     * @param {object} consultationData - The consoltation to be validated, see consultationFormatter.
     */
    validateConsultation: async (consultationData: any) => axios.post('/consultations/validate', consultationData),

    /**
     * A get request to the consultations endpoint will return a paginated list of all consultations in the
     * system, scoped to user.
     * @param {number} page - Current page of consultations.
     * @param {number} perPage - Number of consultations per page (default is 20).
     * @param {string} excluded - Defines statuses to exclude from results, can be comma seperated to filter in multiple statuses.
     * @param {string} status - NDefines statuses to filter from results, can be comma seperated to filter in multiple statuses.
     */
    getAllConsultations: async (page = null, perPage = null, excluded = 'draft', status = null) => {
        const queries = [];

        if (perPage !== null) queries.push(`perPage=${perPage}`);
        if (page !== null) queries.push(`page=${page}`);
        if (excluded !== null) queries.push(`excluded=${encodeURIComponent(excluded)}`);
        if (status !== null) queries.push(`status=${encodeURIComponent(status)}`);

        const queryString = queries.length ? `?${queries.join('&')}` : '';

        return axios.get(`/consultations${queryString}`);
    },

    /**
     * Returns a specified consultation
     * @param {string} uuid - Unique id of the consultation to get.
     */
    getConsultation: async (uuid: string) => axios.get(`/consultations/${uuid}`),
};
