import OrderItem from '../../../../../../types/api/order/OrderItem';

type GetTreatmentImageType = (reference: number) => string;
type GetTreatmentDescriptionType = (reference: number) => string;

export interface FormatFreshDataProductsReturnType {
    treatment: string;
    desc: string;
    image: string;
    name: string;
    id: number;
    quantity: number;
    wasPrice: string;
    nowPrice: string;
    sku: number;
    stock: null;
    url: string;
}

// eslint-disable-next-line arrow-body-style
const formatFreshDataProducts = (
    orderItems: OrderItem[],
    getTreatmentImage: GetTreatmentImageType,
    getTreatmentDescription: GetTreatmentDescriptionType
): FormatFreshDataProductsReturnType[] => orderItems.map((item) => {
    const { condition, reference, treatment, quantity, total } = item; // eslint-disable-line object-curly-newline

    const formattedPrice = ((total || 0) / 100).toFixed(2);

    return {
        treatment: condition || 'General Health', // Treatment condition
        desc: getTreatmentDescription(reference), // Variant description
        image: getTreatmentImage(reference), // Variant image
        name: treatment, // Variant name
        id: reference, // Variant id/sku
        quantity, // Variant quantity
        wasPrice: formattedPrice, // Variant price
        nowPrice: formattedPrice, // Variant price
        sku: reference, // Variant id/sku
        stock: null, // Variant stock
        url: window.location.href,
    };
});

export default formatFreshDataProducts;
