import { HttpBreadcrumb } from '../../types/Breadcrumb';

/**
 * Checks if a breadcrumbs url request is just a gatsby page data request.
 */
const isBreadcrumbAGatsbyPageDataRequest = (breadcrumb: HttpBreadcrumb) => {
    const url = breadcrumb.data && breadcrumb.data.url ? breadcrumb.data.url : '';

    if (!url) {
        return false;
    }

    return url.endsWith('/page-data.json') || url.endsWith('/app-data.json');
};

export default isBreadcrumbAGatsbyPageDataRequest;
