import axios from 'axios';

export interface SiteBanner {
    uuid: string;
    body: string;
    end?: string;
    name: string;
    start?: string;
    status: 'ACTIVE' | 'DISABLED';
}

export default {
    getSiteBanner: async () => axios.get<{ data: SiteBanner[] }>('/banners'),
};
