/**
 * Checks if localStorage is available in the current browser.
 * @returns {boolean}
 */
const isLocalStorageAvailable = (() => {
    // If there is now browser window.
    if (typeof window === 'undefined') {
        return false;
    }

    // Try to add and remove an item.
    try {
        // Get the storage from the window.
        const storage = window.localStorage;

        const storageTest = '__storage_test__';
        storage.setItem(storageTest, storageTest);
        storage.removeItem(storageTest);
        return true;
    } catch (e) {
        return false;
    }
})();

export default isLocalStorageAvailable;
