import { useEffect, useCallback } from 'react';

const useEscKey = (callback) => {
    const escKeyCall = useCallback(
        (e) => {
            if (e.key === 'Escape' && e.keyCode === 27 && callback) callback();
        },
        [callback]
    );

    useEffect(() => {
        if (callback) document.addEventListener('keydown', escKeyCall);

        return () => {
            document.removeEventListener('keydown', escKeyCall);
        };
    }, [escKeyCall]);
};

export default useEscKey;
