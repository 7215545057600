import axios from 'axios';

import { API_URL, MSW_URL } from '../config';
import { localStorageGet } from '../../hooks/useLocalStorageSync';
import { initRefreshInterceptor } from './interceptors/refreshInterceptor';
import { initSentryInterceptor } from './interceptors/sentryInterceptor';
import { getAffiliateJwt } from '../../modules/affiliate/helpers/getAffiliateJwt';

/** Pulled from axios github - to check if a string is an absolute url */
const isAbsoluteURL = (url) => /^([a-z][a-z\d\\+\-\\.]*:)?\/\//i.test(url);

/** Used to check if the link is external to our site. So that we dont pass the bearer */
const isExternal = (url) => {
    const absolute = isAbsoluteURL(url);
    if (absolute) {
        /** If the url is part of our api then we consider this 'not external' */
        if (url.indexOf(API_URL) === -1 && url.indexOf(MSW_URL) === -1) {
            // if (url.indexOf('http://dev.api.theindependentpharmacy.co.uk') === -1) {
            return true;
        }
    }
    return false;
};

export default (() => {
    axios.defaults.baseURL = API_URL;

    axios.interceptors.request.use((cfg) => {
        const newCfg = { ...cfg };

        if (isExternal(newCfg.url)) {
            return newCfg;
        }

        if (!newCfg.headers) {
            newCfg.headers = {};
        }

        const authTokens = localStorageGet('auth');
        const token = authTokens ? authTokens.access_token : undefined;
        if (token) {
            const fullToken = `Bearer ${token}`;
            newCfg.headers.Authorization = fullToken;
        }

        // TODO: Check why it's not being added to unauthed requests.
        const affiliateJwt = getAffiliateJwt();
        if (affiliateJwt) {
            newCfg.headers.Affiliate = affiliateJwt;
        }

        // ssid = sentry session id (created and stored in local storage in our custom sentry plugin to distinguish the user from another user)
        newCfg.headers['X-Transaction-ID'] = localStorageGet('ssid');

        return newCfg;
    });

    /** Report errors and information to sentry from the statuses above */
    initSentryInterceptor(axios);

    /** on 401, try refresh token, if fail then logout and redirect */
    initRefreshInterceptor(axios);
})();
