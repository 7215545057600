/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState, useMemo } from 'react';
import { useNavigationCloseHook } from '../../hooks/useNavigationCloseHook';

/** Start of the basket context. */
const NavigationContext = createContext();

/**
 * This provider handles all of our search state. Wrapping a new provider will create a new scoped-state.
 * Meaning you can have multiple individual searches on a page. Useful for inpage results vs overlay results.
 * @param {*} props
 */
export const NavigationProvider = ({ ...props }) => {
    const [sidebarNavIsOpen, setSidebarNavIsOpen] = useState(false);

    useNavigationCloseHook('mobile', () => setSidebarNavIsOpen(false));

    const value = useMemo(
        () => ({
            sidebarNavIsOpen,
            setSidebarNavIsOpen,
        }),
        [sidebarNavIsOpen, setSidebarNavIsOpen]
    );

    return <NavigationContext.Provider {...props} value={value} />;
};

/**
 * Hook to be used in components that will allow us access to the values passed into the search provider above.
 */
export const useNavigationContext = () => {
    const context = useContext(NavigationContext);

    if (context === undefined) {
        throw new Error('useNavigationContext must be used within a NavigationProvider');
    }

    return context;
};
