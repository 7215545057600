import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
import dayjsIsoWeek from 'dayjs/plugin/isoWeek';

/**
 * Add plugins to dayjs - https://day.js.org/docs/en/installation/installation
 */
export default (() => {
    dayjs.extend(dayjsUtc); // https://day.js.org/docs/en/plugin/utc
    dayjs.extend(dayjsDuration); // https://day.js.org/docs/en/plugin/duration
    dayjs.extend(dayjsIsBetween); // https://day.js.org/docs/en/plugin/is-between
    dayjs.extend(dayjsIsoWeek); // https://day.js.org/docs/en/plugin/iso-week
})();
