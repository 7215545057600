import get from 'lodash.get';
import jwtDecode from 'jwt-decode';

import { AuthToken } from '../../../../types/api/auth/AuthToken';

interface ReturnType {
    userID: string | null;
}

/**
 * Decodes the user UUID from the auth token sent from the API.
 * @param {authTokenInterface} authToken - Token recieved from login request.
 * @returns {ReturnType}
 */
const formatLoginAnalytics = (authToken: AuthToken): ReturnType => {
    try {
        const accessToken = get(authToken, 'access_token');
        const decodedPatient = jwtDecode(accessToken);
        const userID = get(decodedPatient, 'patient_uuid') || null;

        return {
            userID,
        };
    } catch (error) {
        return {
            userID: null,
        };
    }
};

export default formatLoginAnalytics;
