/* eslint-disable max-len */
import get from 'lodash.get';

import { affiliateInScope } from '../../../affiliate/helpers/affiliateInScope';
import { getAffiliateJwtDecoded } from '../../../affiliate/helpers/getAffiliateJwt';
import ProductTypes from '../../../../types/api/products/ProductTypes';
import ConsultationTypes from '../../../../types/api/Consultation/ConsultationTypes';
import { ConsultationMeta } from '../../../../data/context/consultationContext/consultationLauncherContext';

interface AnalyticsData {
    productName: string | 'Undefined';
    productId: string | number | 'Undefined';
    productCategory: string;
    productVariant: string | 'Undefined';
    consultationType: ProductTypes | 'Undefined';
    affiliateCode: string | 'Undefined';
    loggedInStatus: 'Not Logged in';
    'Reorder Status': ConsultationTypes;
}

/**
 * Formats data from the consultation to be sent to google analytics.
 * @param {Treatment} treatment - Treatment data stored in the consultation.
 * @param {boolean} isLoggedIn - If there is a patient logged in when completing the consultation.
 * @param {ConsultationTypes} orderType - NEW, EXPIRED, REFILL.
 * @returns {ReturnType}
 */
const formatConsultationAnalytics = (consultationMeta: ConsultationMeta, isLoggedIn: boolean, orderType: ConsultationTypes) => {
    const analyticsData = {
        productName: 'Undefined',
        productId: 'Undefined',
        productCategory: 'General Health', // Need to add to meta.
        productVariant: 'Undefined',
        consultationType: 'Undefined',
        affiliateCode: 'Undefined',
        loggedInStatus: isLoggedIn ? 'Logged in' : 'Not Logged in',
        'Reorder Status': orderType,
    } as AnalyticsData;

    // Sometimes condition name will be provided if only a condition has been provided as we can't get the
    // condition from the selected variant.
    if (consultationMeta?.conditionName) {
        analyticsData.productCategory = consultationMeta?.conditionName;
    }

    if ('treatmentId' in consultationMeta && consultationMeta.treatmentId) {
        if (consultationMeta.treatmentId) {
            analyticsData.productId = consultationMeta.treatmentId;
        }

        if (affiliateInScope(consultationMeta.treatmentId as number)) {
            analyticsData.affiliateCode = get(getAffiliateJwtDecoded({}), 'affiliate_code', 'Undefined');
        }
    }

    if ('selectedVariant' in consultationMeta && consultationMeta.selectedVariant) {
        if (consultationMeta.selectedVariant.name) {
            analyticsData.productVariant = consultationMeta.selectedVariant.name;
            analyticsData.productName = consultationMeta.selectedVariant.name; // Use the variant name as default.
        }

        if (consultationMeta.selectedVariant.type) {
            analyticsData.consultationType = consultationMeta.selectedVariant.type.toUpperCase() as ProductTypes;
        }
    }

    if ('selectedTreatment' in consultationMeta && consultationMeta.selectedTreatment) {
        if (consultationMeta.selectedTreatment.parent) {
            analyticsData.productName = consultationMeta.selectedTreatment.parent.name; // Override the variant name set above.
        }

        if (consultationMeta.selectedTreatment.condition_name) {
            analyticsData.productCategory = consultationMeta.selectedTreatment.condition_name;
        }
    }

    return analyticsData;
};

export default formatConsultationAnalytics;
