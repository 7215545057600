import { AxiosResponse } from 'axios';
import * as React from 'react';
import useDataApi from './useDataApi';

interface State {
    data: any;
    isError: boolean;
    isLoading: boolean;
    error: any;
}

type GenericFunction = (...args: any[]) => any | (() => Promise<AxiosResponse<any, any>>);

// TODO: Update this and useDataApi to use T.
const useDataApiEffect = <T>(
    service: GenericFunction | null,
    listenerDependencies: T[] = [],
    opts = { defaultLoading: true }
): [State, GenericFunction, GenericFunction, GenericFunction] => {
    const [state, callApi, reset, updateState] = useDataApi(service, listenerDependencies, opts);
    const didCancel = React.useRef<boolean>();

    React.useEffect(() => {
        didCancel.current = false;

        callApi(didCancel);

        return () => {
            didCancel.current = true;
        };
    }, [callApi]);

    return [state, callApi, reset, updateState];
};

export default useDataApiEffect;
