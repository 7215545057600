import jwtDecode from 'jwt-decode';
import { getCookie } from '../../../helpers/cookie';

export type JWTString = string;

export interface AffiliateDecodedJwt {
    affiliate_code: string;
    affiliate_name: string;
    aud: string;
    discount_code?: string;
    discount_label?: string;
    discount_type?: string;
    exp: number;
    expires_after: string;
    iat: number;
    jti: string;
    treatment_scope: string[];
}

/** Get the token */
export const getAffiliateJwt = <T = null>(defaultReturn: null | T = null): T | JWTString =>
    getCookie('affiliate') ? getCookie('affiliate') : defaultReturn;

/** Get the decoded token */
export const getAffiliateJwtDecoded = <T = null>(defaultReturn: null | T = null): T | AffiliateDecodedJwt | null =>
    getCookie('affiliate') ? jwtDecode(getCookie('affiliate')) : defaultReturn;
