import initSentry from "./helpers/initSentry";
import PluginOptions from "./types/PluginOptions";

// TODO: Look at using @sentry/types for sentry typescript types.

/**
 * Custom sentry plugin to init
 * @param {*} param0
 * @param {*} pluginOptions
 * @returns
 */
export const onClientEntry = (_a: any, pluginOptions?: PluginOptions) => {
    if (!pluginOptions || !pluginOptions.enabled) {
        return;
    }

    initSentry(pluginOptions);
};
