import axios from 'axios';

// import { MSW_URL } from '../../config/config';

export default {
    /**
     * Validates an affiliate code.
     * @param {string} code - The affiliate code to validate.
     * @returns {object}
     */
    validateAffiliate: async (code) =>
        axios.post('/affiliates/verify', {
            code,
        }),
};
